
import {combineReducers} from "redux"
import genelReducer from "./recuders/genelReducer"

const rootReducer = combineReducers({
   genel:genelReducer,
})

export default rootReducer;


