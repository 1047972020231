import { createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import rootReducer from "./rootReducer";

//devToolsEnhancer  zorunlu değil
export function configureStore()
{
    return createStore(rootReducer, devToolsEnhancer());
}

