import { CLAIM_ROL, LOADING, ALTMENULIST, MENUSHOW, ENVANTER_IGNE_DATA_TRANSFER_BASLAT, PERSONEL_RESIMLER } from "../actions/genelActions";
import { claimRol, loading, altMenuList, menuShow, timerIdEnvanterIgneDataTransfer, personelResimler, seciliMusteriler } from "../initialValues/genelItems";

const initialState = {
    claimRol: claimRol,
    loading: loading,
    altMenuList: altMenuList,
    menuShow: menuShow,
    timerIdEnvanterIgneDataTransfer: timerIdEnvanterIgneDataTransfer,
    personelResimler:personelResimler
}

const genelReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case CLAIM_ROL:
            return {
                ...state,
                //parametreItems:[...state.parametreItems, payload]
                claimRol: payload
            }
        case LOADING:
            return {
                ...state,
                //parametreItems:[...state.parametreItems, payload]
                loading: payload
            }
        case ALTMENULIST:
            return {
                ...state,
                //parametreItems:[...state.parametreItems, payload]
                altMenuList: payload
            }
        case MENUSHOW:
            return {
                ...state,
                //parametreItems:[...state.parametreItems, payload]
                menuShow: payload
            }
        case ENVANTER_IGNE_DATA_TRANSFER_BASLAT:
            return {
                ...state,
                //parametreItems:[...state.parametreItems, payload]
                timerIdEnvanterIgneDataTransfer: payload
            }
        case PERSONEL_RESIMLER:
            return {
                ...state,
                //parametreItems:[...state.parametreItems, payload]
                personelResimler: payload
            }       
        default:
            return state;
    }
}

export default genelReducer;