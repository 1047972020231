import axios from "axios";
import packageJson from '../../package.json';
import { swalSaveError } from '../utils/sweetalert';
import Swal from 'sweetalert2'
import { httpError } from '../store/initialValues/genelItems';
import { setClaimRol } from '../store/actions/genelActions'

let store

export const setupAxiosInstance = (_store) => {
    store = _store
}

var getUrl = window.location;
axios.defaults.baseURL = "http://" + getUrl.host.split(':')[0] + ":7001/";

if (getUrl.host === "ser-bi.com")
    axios.defaults.baseURL = "https://ser-bi.com/api/";


// Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jw_token');
        if (token) {
            // console.log("config: " + token);
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        //console.log("originalRequest: " + JSON.stringify(originalRequest));
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        // console.log(error.response.data);

        if (error.response.status === 400 && !originalRequest._retry) {

            swalSaveError("Hata oluştu: " + error.response.data, 3000);
        }
       // alert("bu " + error.response.status +"-" + originalRequest._retry +"0")

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
           

            //Eğer kullanıcı oturum açmamışsa mesaj verip sonlandırıyorum
            if (error.response.data != null) {

                if (error.response.data.indexOf('NotIsAuthenticated') !== -1) {
                    Swal.fire({
                        title: "Oturum süreniz sona erdi.",
                        text: "Tekrar giriş yapınız.",
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Tamam"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //Çıkış yapılıyor
                            localStorage.removeItem('jw_token');
                            localStorage.removeItem('jw_token_expiration');
                            localStorage.removeItem('login_user');
                            localStorage.removeItem('login_remember_me');

                            window.location.replace('/');
                        }
                    });
                }
                

                if (error.response.data.indexOf('NotClaimRol_') !== -1) {
                    store.dispatch(setClaimRol({ [error.response.data]: "NotClaimRol" }));
                }
            }

            //Bu bölümde refresh token yapabilirsin
            // try {
            //     const refreshToken = localStorage.getItem('refreshToken');
            //     const response = await axios.post('/api/refresh-token', { refreshToken });
            //     const { token } = response.data;

            //     localStorage.setItem('token', token);

            //     // Retry the original request with the new token
            //     originalRequest.headers.Authorization = `Bearer ${token}`;
            //     return axios(originalRequest);
            // } catch (error) {
            //     // Handle refresh token error or redirect to login
            // }
        }

        return Promise.reject(error);
    }
);

export default axios;

/*

// if(getUrl.host === "srbi.sertim.com.tr")
// axios.defaults.baseURL   = "http://api.serayas.com.tr/";
// else
// axios.defaults.baseURL   = "http://" + getUrl.host.split(':')[0] + ":7001/";


axios.defaults.timeout = 20000;
const onRequestSuccess = config => {


    var jwToken = localStorage.getItem('jw_token');
    if (jwToken != null) {
        alert("onRequestSuccess2" + jwToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwToken}`;
    }
    return config;
};
const onRequestFail = error => {
    return Promise.reject(error);
};
axios.interceptors.request.use(onRequestSuccess, onRequestFail);

const onResponseSuccess = response => {
    return response;
};


const onResponseFail = error =>  {
    if (error != null) {


        if (error.response != null && error.response.status === 401) {
            alert(error.response.data);
            //Eğer kullanıcı oturum açmamışsa mesaj verip sonlandırıyorum
            if (error.response.data != null) {
                if (error.response.data == 'NotIsAuthenticated') {
                    Swal.fire({
                        title: "Oturum süreniz sona erdi.",
                        text: "Tekrar giriş yapınız.",
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Tamam"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //Çıkış yapılıyor
                            localStorage.removeItem('jw_token');
                            localStorage.removeItem('jw_token_expiration');
                            localStorage.removeItem('login_user');
                            localStorage.removeItem('login_remember_me');

                            window.location.replace('/');
                        }
                    });
                }

                if (error.response != null && error.response.data == 'NotClaimRol') {


                }
                return
            }
        }
        swalSaveError(error);
    }
    return Promise.reject(error);
};
axios.interceptors.response.use(onResponseSuccess, onResponseFail);
*/

/*
axios.interceptors.response.use(resp => resp, error => {

    if (error != null && error.response != null) {

        if (error.response.status === 401) {
            //Eğer kullanıcı oturum açmamışsa mesaj verip sonlandırıyorum
            if (error.response.data != null) {
                if (error.response.data == 'NotIsAuthenticated') {
                    Swal.fire({
                        title: "Oturum süreniz sona erdi.",
                        text: "Tekrar giriş yapınız.",
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Tamam"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //Çıkış yapılıyor
                            localStorage.removeItem('jw_token');
                            localStorage.removeItem('jw_token_expiration');
                            localStorage.removeItem('login_user');
                            localStorage.removeItem('login_remember_me');

                            window.location.replace('/');
                        }
                    });
                }


                if (error.response.data == 'NotClaimRol') {


                    Swal.fire({
                        title: "modül yetkisi yok",
                        text: "Tekrar giriş yapınız.",
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Tamam"
                    }).then((result) => {
                        if (result.isConfirmed) {

                        }
                    });
                }
                return
            }
        }

        if (error.response.status === 400) {
            swalSaveError(error.response.data)
        }
        else {
            swalSaveError("Hata kodu:" + error.response.status + " /" + error.response.data)
        }
        return error;
    }
    swalSaveError(error)
    return error;
});
*/


//let refresh = false;
/*
axios.interceptors.response.use(resp => resp, async error => {
    alert("Hata Kodu 1: " + error );

    if (error.response.status === 401 && !refresh) {
        refresh = true;

        const response = await axios.post('refresh', {}, {withCredentials: true});


        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['token']}`;

            return axios("Hata Kodu 200: " + error.config);
        }
    }

    refresh = false;
    return error;
});
*/


// console.log("eroor" + error.response.status);

//     if (error.response.status === 401 && !refresh) {
//         refresh = true;
//         console.log("2 ");

//         const responseRefresh = axios.post('/Auth/Refresh', {}, {}, { withCredentials: true });
//         responseRefresh.then((response) => {

//             console.log("200 =" + JSON.parse(response.status));
//             if (response.status === 200) {

//                 var jwToken = localStorage.getItem('jw_token');
//                 if (jwToken != null) {
//                     console.log("token mutlu: " + jwToken);
//                     axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(jwToken)}`;
//                     return axios("Hata Kodu 200: " + error.config);
//                 }
//             }
//         })

//     }

//     refresh = false;
//     return error;