import React from 'react'
import '../../../assets/css/BouncingLoader.css';


export const Alert = {
    YetkiYok
};

export function YetkiYok() {
    return (
        <div className="alert alert-danger m-2" role="alert">   
            <img src="/images/genel/giris_yok64x64.png" height={50} width={50} />
            &nbsp;
         <b>Kilitli</b>   
        </div>
    );
}



