import React from 'react'
import '../../../assets/css/BouncingLoader.css';


export const Spinner = {
    Grow,
    FlexContentCenter,
    Bouncingloader
};

export function Grow() {
    return (
        <div className="text-center" style={{ width: "100%" }}>
            <div className="spinner-grow" role="status" >
                <span span className="sr-only">Yükleniyor...</span>
            </div>
        </div>
    );
}
export function FlexContentCenter() {
    return (
        <div className="d-flex justify-content-center p-5">
            <div className="spinner-border text-danger" role="status">
            </div>
            <span className="visually-hidden">Yükleniyor...</span>
        </div>
    );
}

export function Bouncingloader() {
    return (
        <div className="bouncing-loader">
            <div>Y</div>
            <div>Ü</div>
            <div>K</div>
            <div>L</div>
            <div>E</div>
            <div>N</div>
            <div>İ</div>
            <div>Y</div>
            <div>O</div>
            <div>R</div>
        </div>
    );
}



