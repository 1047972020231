
import Swal from 'sweetalert2'

export function swalSave(title, timer = 1500) {
  Swal.fire({
    position: 'top-center',
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
  })

  return null;
}
export function swalSaveError(text, timer = -1) {
  if (timer === -1) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: text
    })
  }
  else {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: text,
      timer: timer
    })
  }

  return null;
}
