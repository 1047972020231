//Notlar var dateObj = new Date();  Date().toISOString().slice//0,10)

export function getDate() {
    var dateObj = new Date();
    var month = dateObj.getMonth() + 1;
    if (month < 10) { month = "0" + month; }
    var day = String(dateObj.getDate()); //.padStart(2, '0');
    if (day < 10) { day = "0" + day; }
    const year = dateObj.getFullYear();
    var Tarih = year + "-" + month + "-" + day;
    return Tarih;
}

export function getTime() {
    var dateObj = new Date();
    var hour = dateObj.getHours();
    if (hour < 10) { hour = "0" + hour; }
    var minute = dateObj.getMinutes();
    if (minute < 10) { minute = "0" + minute; }
    var second = dateObj.getSeconds();
    if (second < 10) { second = "0" + second; }

    return hour + ":" + minute + ":" + second;
}

export function getBack(tarih) {


    var d = new Date(tarih);
    d.setDate(d.getDate() - 1);
    var month = d.getMonth() + 1;
    if (month < 10) { month = "0" + month; }
    var day = String(d.getDate()); //.padStart(2, '0');
    if (day < 10) { day = "0" + day; }
    const year = d.getFullYear();
    var Tarih = year + "-" + month + "-" + day;
    return Tarih;
}
export function getNext(tarih) {

    var d = new Date(tarih);
    d.setDate(d.getDate() + 1);
    var month = d.getMonth() + 1;
    if (month < 10) { month = "0" + month; }
    var day = String(d.getDate()); //.padStart(2, '0');
    if (day < 10) { day = "0" + day; }
    const year = d.getFullYear();
    var Tarih = year + "-" + month + "-" + day;

    return Tarih;
}
export function getYear() {

    var d = new Date();
    const year = d.getFullYear();
    return year;
}

export function getAyAd(ayId) {

    switch (ayId) {
        case 1:
            return "OCAK";
        case 2:
            return "ŞUBAT";
        case 3:
            return "MART";
        case 4:
            return "NİSAN";
        case 5:
            return "MAYIS";
        case 6:
            return "HAZİRAN";
        case 7:
            return "TEMMUZ";
        case 8:
            return "AĞUSTOS";
        case 9:
            return "EYLÜL";
        case 10:
            return "EKİM";
        case 11:
            return "KASIM";
        case 12:
            return "ARALIK";
        default:
        // code block
    }
    return null;
}
export function getYilBasi() {
    var dateObj = new Date();
    const year = dateObj.getFullYear();
    var month = dateObj.getMonth() + 1;
    if (month < 10) { month = "0" + month; } 
    var Tarih = year + "-01-01";  
    return Tarih;
}

export function getAySonu() {
    var dateObj = new Date();
    const year = dateObj.getFullYear();
    var month = dateObj.getMonth() + 1;
    if (month < 10) { month = "0" + month; }
    var day = new Date(year, month, 0).getDate();
    var Tarih = year + "-" + month + "-" + day;  
    return Tarih;
}
export function getAyBasi() {
    var dateObj = new Date();
    const year = dateObj.getFullYear();
    var month = dateObj.getMonth() + 1;
    if (month < 10) { month = "0" + month; } 
    var Tarih = year + "-" + month + "-01";  
    return Tarih;
}


