export const CLAIM_ROL    = "CLAIM_ROL"
export const LOADING    = "LOADING"
export const ALTMENULIST   = "ALTMENULIST"
export const MENUSHOW   = "MENUSHOW"
export const ENVANTER_IGNE_DATA_TRANSFER_BASLAT   = "ENVANTER_IGNE_DATA_TRANSFER_BASLAT"
export const PERSONEL_RESIMLER   = "PERSONEL_RESIMLER"


export const setClaimRol=(item)=>({
    type:CLAIM_ROL,
    payload :  item
})

export const setLoading=(item)=>({
   
    type:LOADING,
    payload :  item
})


export const setAltMenuList=(item)=>({   
    type:ALTMENULIST,
    payload :  item
})
export const setMenuShow=(item)=>({   
    type:MENUSHOW,
    payload :  item
})

export const startEnvanterIgneDataTransfer=(item)=>({
    type:ENVANTER_IGNE_DATA_TRANSFER_BASLAT,
    payload : item
})
export const setPersonelResimler=(item)=>({
    type:PERSONEL_RESIMLER,
    payload : item
})


