import React, { useState, useEffect, Suspense } from 'react'
import { lazyWithRetry } from './utils/lazyWithRetry'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Spinner } from './components/lib'
import './utils/i18n'



/*
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
*/
import 'react-toastify/dist/ReactToastify.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";

const Login = lazyWithRetry(() => import('./pages/Login/Login'))
const DefaultLayout = React.lazy(() => import('./components/layout/DefaultLayout'))

function App() {


  return (   
      <HashRouter>
        <Suspense fallback={<Spinner.FlexContentCenter />}>
          <Routes>
            <Route exact path="/login" name="Login" element={<Login />} />
            <Route exact path="*" name="layout" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
  
  );
}

export default App;

/*
<Route exact path={ROOT} element={<HomePage />} />
<Route exact path={LOGIN_PAGE} element={<LoginPage />} />
*/