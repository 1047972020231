import React, { useState, useEffect } from 'react'
import { buGun, dun } from '../../../utils/tarih'
import { getBack, getNext } from '../../../utils/datetime'

export default function TarihGun(props) {


    const [tarih, setTarih] = useState({
        Tarih: buGun(),
        TipAd: props.TipAd
    });



    useEffect(() => {

        tarihTip(props.Tip);

    }, []);

    const onDateBasChange = (event) => {

        if (event.target.value !== "") {
            setTarih((tarih) => ({ ...tarih, TipAd: "Seçim", Tarih: event.target.value }));

            props.runFunction(
                {
                    Tarih: event.target.value
                }
            );
        }
    };

    function tarihTip(tip) {
        let lctarih = tarih.BasTarih;
        let tipAd = tarih.tipAd;

        if (tip === "bugun") {
            lctarih = buGun();
            tipAd = "Bu Gün";
        }


        if (tip === "dun") {
            lctarih = dun().baslangic;
            tipAd = "Dün";
        }

        setTarih((tarih) => ({ ...tarih, TipAd: tipAd, Tarih: lctarih }));

        props.runFunction(
            {
                Tarih: lctarih,
            }
        );
    }
    const onDateChange = (event) => {
        tarihTip(event.target.name);
    };
    const onDateGeri = () => {

        var geriTarih = getBack(tarih.Tarih);
        setTarih((tarih) => ({ ...tarih, TipAd: "Seçim", Tarih:geriTarih}));
        props.runFunction(
            {
                Tarih: geriTarih,
            }
        );
    };
    const onDateIleri = () => {

        var ileriTarih = getNext(tarih.Tarih);
        setTarih((tarih) => ({ ...tarih, TipAd: "Seçim", Tarih:ileriTarih}));
        props.runFunction(
            {
                Tarih: ileriTarih,
            }
        );
    };

    return (
        <>
            
            <div className="input-group-prepend" onClick={() => onDateGeri()}>
                <button type="button" className="btn btn-sm btn-default" style={{ backgroundColor: "#FF3131", color: "white" }}>&lt;</button>
            </div>

            <input type="date" name="Tarih" data-date-format="DD.MM.YYYY" className="form-control form-control-sm text-center" defaultValue={tarih.Tarih} 
                onChange={onDateBasChange} value={tarih.Tarih}
                style={{fontSize:"12px"}}
            />
            <div className="input-group-append"  onClick={() => onDateIleri()}>
                <button type="button" className="btn btn-sm btn-default" style={{ backgroundColor: "#FF3131", color: "white", fontSize:"13px"}}><b>&gt;</b></button>
            </div>
        </>

    )
}
