
//cayrek1Baslangic.toISOString().split('T')[0],

function formatTarih(tarih) {
    const yil = tarih.getFullYear();
    const ay = String(tarih.getMonth() + 1).padStart(2, '0'); // Ay indeksi 0'dan başladığı için +1 ekliyoruz ve padStart metodu ile 2 haneli olacak şekilde düzenliyoruz
    const gun = String(tarih.getDate()).padStart(2, '0');

    return `${yil}-${ay}-${gun}`;
}

export function buGun() {

    // const tarih1 = tarih.toLocaleDateString("tr-TR", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit"
    // });

    const bugun = new Date();
    return formatTarih(bugun);
}
export function dun() {
   
    const bugun = new Date();
    bugun.setDate(bugun.getDate() -1);
    return {
        baslangic: formatTarih(bugun),
        bitis: formatTarih(bugun)
    };
}
export function buHafta() {

    const bugun = new Date();
    const gunIndex = bugun.getDay(); // Haftanın gün indeksi (Pazar: 0, Pazartesi: 1, ..., Cumartesi: 6)
    const baslangicGun = new Date(bugun);
    baslangicGun.setDate(bugun.getDate() - gunIndex + (gunIndex === 0 ? -6 : 1)); // Pazartesi gününe ayarla

    const bitisGun = new Date(baslangicGun);
    bitisGun.setDate(baslangicGun.getDate() + 6); // Haftanın son gününe ayarla

    return {
        baslangic: formatTarih(baslangicGun),
        bitis: formatTarih(bitisGun)
    };
}
export function gecenHafta() {

    const bugun = new Date();
    bugun.setDate(bugun.getDate() - 7);

    const gunIndex = bugun.getDay(); // Haftanın gün indeksi (Pazar: 0, Pazartesi: 1, ..., Cumartesi: 6)
    const baslangicGun = new Date(bugun);
    baslangicGun.setDate(bugun.getDate() - gunIndex + (gunIndex === 0 ? -6 : 1)); // Pazartesi gününe ayarla

    const bitisGun = new Date(baslangicGun);
    bitisGun.setDate(baslangicGun.getDate() + 6); // Haftanın son gününe ayarla

    return {
        baslangic: formatTarih(baslangicGun),
        bitis: formatTarih(bitisGun)
    };
}

export function buAy() {
    const bugun = new Date();
    const buAyinSonu = new Date(bugun.getFullYear(), bugun.getMonth() + 1, 0);
    const buAyinbasi = new Date(bugun.getFullYear(), bugun.getMonth(), 1);
  
    return {
        baslangic: formatTarih(buAyinbasi),
        bitis: formatTarih(buAyinSonu)
    };
}
export function gecenAy() {
    const bugun = new Date();   
    const buAyinbasi = new Date(bugun.getFullYear(), bugun.getMonth()-1, 1);
    const buAyinSonu = new Date(bugun.getFullYear(), bugun.getMonth(), 0);
    return {
        baslangic: formatTarih(buAyinbasi),
        bitis: formatTarih(buAyinSonu)
    };
}
export function buYil() {
    const bugun = new Date();
    const buYilinBasi = new Date(bugun.getFullYear(), 0, 1);
    const buYilinSonu = new Date(bugun.getFullYear(), 11, 31);

    return {
        baslangic: formatTarih(buYilinBasi),
        bitis: formatTarih(buYilinSonu)
    };
}
export function gecenYil() {
    const bugun = new Date();
    const buYilinBasi = new Date(bugun.getFullYear() -1, 0, 1);
    const buYilinSonu = new Date(bugun.getFullYear() -1, 11, 31);

    return {
        baslangic: formatTarih(buYilinBasi),
        bitis: formatTarih(buYilinSonu)
    };
}
export function ceyrek1() {
    const bugun = new Date();
    const cayrek1Baslangic = new Date(bugun.getFullYear(), 0, 1);
    const cayrek1Bitis = new Date(bugun.getFullYear(), 2, 31);

    return {
        baslangic:  formatTarih(cayrek1Baslangic),
        bitis: formatTarih(cayrek1Bitis) 
    };
}
export function ceyrek2() {
    const bugun = new Date();
    const cayrek1Baslangic = new Date(bugun.getFullYear(), 3, 1);
    const cayrek1Bitis = new Date(bugun.getFullYear(), 5, 30);

    return {
        baslangic:  formatTarih(cayrek1Baslangic),
        bitis: formatTarih(cayrek1Bitis) 
    };
}
export function ceyrek3() {
    const bugun = new Date();
    const cayrek1Baslangic = new Date(bugun.getFullYear(), 6, 1);
    const cayrek1Bitis = new Date(bugun.getFullYear(), 8, 30);

    return {
        baslangic:  formatTarih(cayrek1Baslangic),
        bitis: formatTarih(cayrek1Bitis) 
    };
}

export function ceyrek4() {
    const bugun = new Date();
    const cayrek1Baslangic = new Date(bugun.getFullYear(), 8, 1);
    const cayrek1Bitis = new Date(bugun.getFullYear(), 11, 31);

    return {
        baslangic:  formatTarih(cayrek1Baslangic),
        bitis: formatTarih(cayrek1Bitis) 
    };
}

export function ay(ayId) {
    const bugun = new Date();   
    const buAyinbasi = new Date(bugun.getFullYear(), ayId-1, 1);

    const buAyinSonu = new Date(bugun.getFullYear(), ayId, 0);

    return {
        baslangic: formatTarih(buAyinbasi),
        bitis: formatTarih(buAyinSonu)
    };
}

export function formatDateTr(value) {
    const date = new Date(value);
    //console.log("yıl" + date.getFullYear())
    if(date.getFullYear() < 2000) return null
    return  date.toLocaleDateString('tr-TR');
}