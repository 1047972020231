import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend  from "i18next-http-backend"

// const resources ={
//     tr : {
//         translation : 
//         {
//             welcome :  "Hoşgeldiniz"
//         }
//     },
//     en : {
//         translation : 
//         {
//             welcome :  "Welcom"
//         }
//     }
// }

i18n.use(initReactI18next)
.use(Backend)
.init({
    lng:localStorage.getItem('language') === null ? "tr" : localStorage.getItem('language'),
    // resources
})

export default i18n;